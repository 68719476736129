import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Buttons from "../../library/custom/button/Button";
// import TableRowAction from '../../library/custom/gridTable/TableRowAction';
import GridTable from "../../library/custom/gridTable/GridTable";
import SettingTable from "../../library/custom/gridTable/SettingTable";
import TableRowAction from "../../library/custom/gridTable/TableRowAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommunityAdmin,
  postToggleCommunity,
} from "../../redux/actions/communityAction";
import moment from "moment";
import { useNavigate } from "react-router";
import { CREATE_COMMUNITY } from "../../constant/routeContant";
import Loader from "../../library/common/Loader";

const dataCorporate = [
  {
    id: 1,
    orderNumber: "MUIT-U-0421-1",
    transactionId: "trail",
    userId: "user-71509bf0-9ecb-11eb-b4bd-55725f3de7cb",
    is_Active: true,
    admin_Reason: "NO",
    userName: "Lalit Dhingra Corp",
    userType: "corp",
    userEmail: "lalit.dhingra@ensignis.com",
    plan: "Trail",
    planValue: 5,
    planStartDate: "2021-04-16T15:50:18.000Z",
    planEndDate: "2022-04-16T15:50:18.000Z",
    expired: "Yes",
  },
  {
    id: 2,
    orderNumber: "MUIT-U-0421-1",
    transactionId: "trail",
    userId: "user-71509bf0-9ecb-11eb-b4bd-55725f3de7cb",
    is_Active: true,
    admin_Reason: "NO",
    userName: "Lalit Dhingra Corp",
    userType: "cprp",
    userEmail: "lalit.dhingra@ensignis.com",
    plan: "Trail",
    planValue: 5,
    planStartDate: "2021-04-16T15:50:18.000Z",
    planEndDate: "2022-04-16T15:50:18.000Z",
    expired: "Yes",
  },
  {
    id: 3,
    orderNumber: "MUIT-U-0421-1",
    transactionId: "trail",
    userId: "user-71509bf0-9ecb-11eb-b4bd-55725f3de7cb",
    is_Active: false,
    admin_Reason: "NO",
    userName: "Lalit Dhingra Corp",
    userType: "corp",
    userEmail: "lalit.dhingra@ensignis.com",
    plan: "Trail",
    planValue: 5,
    planStartDate: "2021-04-16T15:50:18.000Z",
    planEndDate: "2022-04-16T15:50:18.000Z",
    expired: "Yes",
  },
];
const AdminCommunity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCommunityAdmin());
  }, []);

  const getCommunityAdminData = useSelector(
    (state) => state?.getCommunityAdmin?.data?.data
  );

  const postToggleCommunityData = useSelector(
    (state) => state?.postToggleCommunity
  );
  const columns = [
    { field: "title", headerName: "Name", flex: 2 },
    { field: "userCount", headerName: "Members", flex: 1 },
    { field: "name", headerName: "Created By", flex: 1.5 },
    { field: "createdOn", headerName: "Created On", flex: 1.5 },
    { field: "is_active", headerName: "Status", flex: 1 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={0}
          // numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={dataCorporate ?? []}
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            deleteOnClick={() => {
              dispatch(
                postToggleCommunity({
                  communityId: rows.row._id,
                })
              );
              //   deleteAsset(rows.id);
            }}
            editOnClick={
              () => {
                navigate(CREATE_COMMUNITY, {
                  state: {
                    editMode: true,
                    data: rows?.row,
                  },
                });
              }
              // handleEditOnclick(rows)
            }
            deleted
            edit
            deleteLabel={
              rows?.row?.is_active === "Active"
                ? "Deactive Community"
                : "Activate Community"
            }
            editLabel="Edit Community"
          />
        );
      },
    },
  ];

  const [communityList, setcommunityList] = useState([]);

  useEffect(() => {
    fetchList();
  }, [getCommunityAdminData]);

  const fetchList = (data) => {
    let communityList = [];
    getCommunityAdminData?.map((i) => {
      i.createdOn = moment(i.createdOn).format("MMM Do YYYY");
      i.is_active = i.is_active ? "Active" : "Inactive";
      communityList = [...communityList, i];
    });
    setcommunityList(communityList);
  };

  useEffect(() => {
    if (
      postToggleCommunityData.status === "SUCCESS" &&
      !postToggleCommunityData.isLoading
    )
      dispatch(getCommunityAdmin());
  }, [postToggleCommunityData]);

  const isLoading = useSelector((state) => state?.getCommunityAdmin?.isLoading);
  const toggleLoader = postToggleCommunityData?.isLoading;
  return (
    <div>
        {
            toggleLoader && <Loader/>
        }
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid container mt={2} mb={2}>
            <Buttons
              onClick={() => navigate(CREATE_COMMUNITY)}
              varientContained={true}
              label={"Create New"}
            />
          </Grid>

          <GridTable
            getRowId={(r) => r._id}
            rows={communityList ?? []}
            columns={columns}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
          />
        </Grid>
      )}
    </div>
  );
};

export default AdminCommunity;
