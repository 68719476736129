import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import AppMenu from "./SideBarMui";
import { ACCOUNT_TYPE } from "../../constant/constant";
import SidebarAdmin from "./SidebarAdmin";
import ResponsiveDrawer from "./ResponsiveDrawer";
import ReactGA from "react-ga4";
ReactGA.initialize("G-3QX8Q8CS0E");
export default function Sidebar() {
  const naviagte = useNavigate();
  function handleRoute(route) {
    naviagte(route);
  }
  const pathName = window.location.pathname
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathName, title: pathName });
  }, [pathName])
  
  return (
    <div >
      <div>
        {ACCOUNT_TYPE() === "admin" ? (
          <SidebarAdmin handleRoute={handleRoute} />
        ) : (
          //  <AppMenu handleRoute={handleRoute} />
          <ResponsiveDrawer />
        )}{" "}
      </div>
    </div>
  );
}
