import { countryCode } from "../config";
import { styled } from "@mui/material/styles";
import team1 from "../assets/Landing/team1.png";
import team2 from "../assets/Landing/team2.png";
import team3 from "../assets/Landing/team3.png";
import team4 from "../assets/Landing/team4.png";

import Switch from "@mui/material/Switch";
export const JITSI_APP_ID =
  "vpaas-magic-cookie-c7edf14b732a42abaf37a31fcd6eb0f5";
export const USERID = sessionStorage.getItem("id");
export const ACCOUNT_TYPE = () =>
  sessionStorage.getItem("account_type") ??
  sessionStorage.getItem("account_type");

export const INTRO_VID =
  "https://muit-media.s3.amazonaws.com/user-658903e0-8a6d-11ed-979a-93262c463289/1724848880019_MIT_video_aug_24_w_music_%281%29_%281%29_%281%29.mp4";

export const USER_ID = () =>
  sessionStorage.getItem("userId") ?? sessionStorage.getItem("id");
export const LANGUAGES = {
  US: "en-US",
  MX: "es-MX",
};

export const API_STATUS = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  PENDING: "PENDING",
};

export const PAGE_SIZE = 10;
export const DOCUMENT_UPLOAD_MAX_NUM = 5;
export const DOCUMENT_UPLOAD_MAX_NUM_ONE = 1;
//export const DOCUMENT_UPLOAD_MAX_SIZE = 10485760;
export const DOCUMENT_UPLOAD_MAX_SIZE = 4718592;

export const API_COMPANY_DATA = [
  { key: "1", value: "Abc company" },
  { key: "2", value: "Abc company2" },
];

export const docMaxSize = 10485760;
export const logoMaxSize = 204800;
export const videoMaxSize = 10485760;
export const imgMaxSize = 10485760;

export const COUNTRY_CODE = "+" + countryCode;
export const COUNTRY_CODE_ENCODED = "%2B" + countryCode;

// export const LANGUAGE = [
//   { key: 1, value: "English" },
//   { key: 2, value: "Spanish" },
// ];
export const EXPERIANCE = ["0 - 1", "1 - 2", "2 - 4"];

export const TEXT_FIELD_ERROR_MSG = "Please enter this required field.";
export const DROP_DOWN_FIELD_ERROR_MSG =
  "Selection is required for this field ";

export const RESOURCETYPES = [
  { key: 1, value: "Document" },
  { key: 2, value: "Link" },
];

export const MAP_KEY =
  "pk.eyJ1IjoibXVuc2FmYSIsImEiOiJjbGVkeXdtYWQwYnNnM3BtajhkMTBoeHFhIn0.ZXVIRCv0cAmrNJbQIvTPUA";

export const EDIT_PROFILE = [
  { key: 1, value: "Basic Details" },
  { key: 2, value: "Work Expirience" },
  { key: 3, value: "Education" },
  { key: 4, value: "Skills & Tools" },
];

export const GENDER = ["Male", "Female", "Others"];
export const EXP = ["Software Engg", "IT", "Others"];
export const FUN = ["Software Engg", "IT", "Others"];
export const ROLE = ["Software Engg", "IT", "Others"];
export const MIN_EXP = ["0 - 1", "1 - 3", "3 - 5", "5 - 10", "10+", "20+"];
export const EMP_TYPE = [
  "Full Time",
  "Part Time",
  "Contract",
  "Self-Employed",
  "Freelance",
  "Intern",
  "Remote",
];
export const REMOTE_ACPT = ["Yes", "No"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const MONTHS_KEY_VAL = [
  { value: "January", key: 1 },
  { value: "February", key: 2 },
  { value: "March", key: 3 },
  { value: "April", key: 4 },
  { value: "May", key: 5 },
  { value: "June", key: 6 },
  { value: "July", key: 7 },
  { value: "August", key: 8 },
  { value: "September", key: 9 },
  { value: "October", key: 10 },
  { value: "November", key: 11 },
  { value: "December", key: 12 },
];
export const TIEM_SLOTS = [
  "01:00-02:00",
  "01:30-02:30",
  "02:00-03:00",
  "02:30-03:30",
  "03:00-04:00",
  "03:30-04:30",
  "04:00-05:00",
  "04:30-05:30",
  "05:00-06:00",
  "05:30-06:30",
  "06:00-07:00",
  "06:30-07:30",
  "07:00-08:00",
  "07:30-08:30",
  "08:00-09:00",
  "08:30-09:30",
  "09:00-10:00",
  "09:30-10:30",
  "10:00-11:00",
  "10:30-11:30",
  "11:00-12:00",
  "11:30-12:30",
  "12:00-13:00",
  "12:30-13:30",
  "13:00-14:00",
  "13:30-14:30",
  "14:00-15:00",
  "14:30-15:30",
  "15:00-16:00",
  "15:30-16:30",
  "16:00-17:00",
  "16:30-17:30",
  "17:00-18:00",
  "17:30-18:30",
  "18:00-19:00",
  "18:30-19:30",
  "19:00-20:00",
  "19:30-20:30",
  "20:00-21:00",
  "20:30-21:30",
  "21:00-22:00",
  "21:30-22:30",
  "22:00-23:00",
  "22:30-23:30",
];

export const S_YEAR = new Date().getFullYear() - 60;
export const E_YEAR = new Date().getFullYear();

export const EMPLOYMENT_TYPE = [
  {
    key: "ft",
    value: "Full Time",
  },
  {
    key: "pt",
    value: "Part Time",
  },
  {
    key: "ct",
    value: "Contract",
  },
  {
    key: "se",
    value: "Self-Employed",
  },
  {
    key: "fr",
    value: "Freelance",
  },
  {
    key: "in",
    value: "Intern",
  },
  {
    key: "re",
    value: "Remote",
  },
];
export const allQualType = [
  "B.Tech",
  "BCA",
  "B.Sc-IT",
  "M.Sc-IT",
  "MCA",
  "M.Tech",
];

export const BOARD_TYPE = ["Non Profit", "Advisory", "Private", "Public"];

export const ISwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#64E562" : "#64E562",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        backgroundColor: "#FFFFFF",
        border: "1px solid #DDE2E5",
        margin: 0,
        marginLeft: 4,
        marginTop: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "#DDE2E5",
    border: "1px solid #DDE2E5",
    margin: 0,
    marginLeft: 5,
    marginTop: 0,
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#FFFFFF",
    border: "1px solid #DDE2E5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    margin: 0,
    marginLeft: 4,
  },
}));
export const COMPANY_TYPE = [
  {
    key: "Private",
    value: "Private Organization",
  },
  {
    key: "Public",
    value: "Public Organization",
  },
  {
    key: "Startup",
    value: "Startup Organization",
  },
];

export const CURRENCY_TYPE = [
  {
    key: "USD",
    value: "$ USD",
  },
  {
    key: "INR",
    value: "INR",
  },
  {
    key: "EURO",
    value: "EURO",
  },
];

export const SALARY_RANGE_INR = [
  {
    rowNumner: "r1",
    key: 1,
    value: "0.0M - 0.5M",
    partitation: ["0.1", "0.2", "0.3", "0.4"],
    marks: [
      { value: 100000, label: "0.1" },
      { value: 200000, label: "0.2" },
      { value: 300000, label: "0.3" },
      { value: 400000, label: "0.4" },
    ],
    minSal: 0,
  },
  {
    rowNumner: "r2",
    key: 2,
    value: "0.5M - 1.0M",
    partitation: ["0.6", "0.7", "0.8", "0.9"],
    marks: [
      { value: 600000, label: "0.6" },
      { value: 700000, label: "0.7" },
      { value: 800000, label: "0.8" },
      { value: 900000, label: "0.9" },
    ],
    minSal: 500000,
  },
  {
    rowNumner: "r3",
    key: 3,
    value: "1.0M - 1.5M",
    partitation: ["1.1", "1.2", "1.3", "1.4"],
    marks: [
      { value: 1100000, label: "1.1" },
      { value: 1200000, label: "1.2" },
      { value: 1300000, label: "1.3" },
      { value: 1400000, label: "1.4" },
    ],
    minSal: 1000000,
  },
  {
    rowNumner: "r4",
    key: 4,
    value: "1.5M - 2.0M ",
    partitation: ["1.6", "1.7", "1.8", "1.9"],
    marks: [
      { value: 1600000, label: "1.6" },
      { value: 1700000, label: "1.7" },
      { value: 1800000, label: "1.8" },
      { value: 1900000, label: "1.9" },
    ],
    minSal: 1500000,
  },
  {
    rowNumner: "r5",
    key: 5,
    value: "2.0M - 2.5M",
    partitation: ["2.1", "2.2", "2.3", "2.4"],
    marks: [
      { value: 2100000, label: "2.1" },
      { value: 2200000, label: "2.2" },
      { value: 2300000, label: "2.3" },
      { value: 2400000, label: "2.4" },
    ],
    minSal: 2000000,
  },
  {
    rowNumner: "r6",
    key: 6,
    value: "2.5M - 3.0M",
    partitation: ["2.6", "2.7", "2.8", "2.9"],
    marks: [
      { value: 2600000, label: "2.6" },
      { value: 2700000, label: "2.7" },
      { value: 2800000, label: "2.8" },
      { value: 2900000, label: "2.9" },
    ],
    minSal: 2500000,
  },
  {
    rowNumner: "r7",
    key: 7,
    value: "3.0M - 3.5M",
    partitation: ["3.1", "3.2", "3.3", "3.4"],
    marks: [
      { value: 3100000, label: "3.1" },
      { value: 3200000, label: "3.2" },
      { value: 3300000, label: "3.3" },
      { value: 3400000, label: "3.4" },
    ],
    minSal: 3000000,
  },
  {
    rowNumner: "r8",
    key: 8,
    value: "3.5M - 4.0M",
    partitation: ["3.6", "3.7", "3.8", "3.9"],
    marks: [
      { value: 3600000, label: "3.6" },
      { value: 3700000, label: "3.7" },
      { value: 3800000, label: "3.8" },
      { value: 3900000, label: "3.9" },
    ],
    minSal: 3500000,
  },
  {
    rowNumner: "r9",
    key: 9,
    value: "4.0M - 4.5M",
    partitation: ["4.1", "4.2", "4.3", "4.4"],
    marks: [
      { value: 4100000, label: "4.1" },
      { value: 4200000, label: "4.2" },
      { value: 4300000, label: "4.3" },
      { value: 4400000, label: "4.4" },
    ],
    minSal: 4000000,
  },
  {
    rowNumner: "r10",
    key: 10,
    value: "4.5M - 5.0M",
    partitation: ["4.6", "4.7", "4.8", "4.9"],
    marks: [
      { value: 4600000, label: "4.6" },
      { value: 4700000, label: "4.7" },
      { value: 4800000, label: "4.8" },
      { value: 4900000, label: "4.9" },
    ],
    minSal: 4500000,
  },
  {
    rowNumner: "r11",
    key: 11,
    value: "5.0M - 5.5M",
    partitation: ["5.1", "5.2", "5.3", "5.4"],
    marks: [
      { value: 5100000, label: "5.1" },
      { value: 5200000, label: "5.2" },
      { value: 5300000, label: "5.3" },
      { value: 5400000, label: "5.4" },
    ],
    minSal: 5000000,
  },
  {
    rowNumner: "r12",
    key: 12,
    value: "5.5M - 6.0M",
    partitation: ["5.6", "5.7", "5.8", "5.9"],
    marks: [
      { value: 5600000, label: "5.6" },
      { value: 5700000, label: "5.7" },
      { value: 5800000, label: "5.8" },
      { value: 5900000, label: "5.9" },
    ],
    minSal: 5500000,
  },
  {
    rowNumner: "r13",
    key: 13,
    value: "6.0M - 6.5M",
    partitation: ["6.1", "6.2", "6.3", "6.4"],
    marks: [
      { value: 6100000, label: "6.1" },
      { value: 6200000, label: "6.2" },
      { value: 6300000, label: "6.3" },
      { value: 6400000, label: "6.4" },
    ],
    minSal: 6000000,
  },
  {
    rowNumner: "r14",
    key: 14,
    value: "6.5M - 7.0M",
    partitation: ["6.6", "6.7", "6.8", "6.9"],
    marks: [
      { value: 6600000, label: "6.6" },
      { value: 6700000, label: "6.7" },
      { value: 6800000, label: "6.8" },
      { value: 6900000, label: "6.9" },
    ],
    minSal: 6500000,
  },
  {
    rowNumner: "r15",
    key: 15,
    value: "7.0M - 7.5M",
    partitation: ["7.1", "7.2", "7.3", "7.4"],
    marks: [
      { value: 7100000, label: "7.1" },
      { value: 7200000, label: "7.2" },
      { value: 7300000, label: "7.3" },
      { value: 7400000, label: "7.4" },
    ],
    minSal: 7000000,
  },
  {
    rowNumner: "r16",
    key: 16,
    value: "7.5M - 8.0M",
    partitation: ["7.6", "7.7", "7.8", "7.9"],
    marks: [
      { value: 7600000, label: "7.6" },
      { value: 7700000, label: "7.7" },
      { value: 7800000, label: "7.8" },
      { value: 7900000, label: "7.9" },
    ],
    minSal: 7500000,
  },
  {
    rowNumner: "r17",
    key: 17,
    value: "8.0M - 8.5M",
    partitation: ["8.1", "8.2", "8.3", "8.4"],
    marks: [
      { value: 8100000, label: "8.1" },
      { value: 8200000, label: "8.2" },
      { value: 8300000, label: "8.3" },
      { value: 8400000, label: "8.4" },
    ],
    minSal: 8000000,
  },
  {
    rowNumner: "r18",
    key: 18,
    value: "8.5M - 9.0M",
    partitation: ["8.6", "8.7", "8.8", "8.9"],
    marks: [
      { value: 8600000, label: "8.6" },
      { value: 8700000, label: "8.7" },
      { value: 8800000, label: "8.8" },
      { value: 8900000, label: "8.9" },
    ],
    minSal: 8500000,
  },
  {
    rowNumner: "r19",
    key: 19,
    value: "9.0M - 9.5M",
    partitation: ["9.1", "9.2", "9.3", "9.4"],
    marks: [
      { value: 9100000, label: "9.1" },
      { value: 9200000, label: "9.2" },
      { value: 9300000, label: "9.3" },
      { value: 9400000, label: "9.4" },
    ],
    minSal: 9000000,
  },
  {
    rowNumner: "r20",
    key: 20,
    value: "9.5M - 10.0M",
    partitation: ["9.6", "9.7", "9.8", "9.9"],
    marks: [
      { value: 9600000, label: "9.6" },
      { value: 9700000, label: "9.7" },
      { value: 9800000, label: "9.8" },
      { value: 9900000, label: "9.9" },
    ],
    minSal: 9500000,
  },
];

export const SALARY_RANGES = [
  {
    rowNumner: "r1",
    key: 1,
    value: "0.0M - 0.5M",
    partitation: ["0.1", "0.2", "0.3", "0.4"],
    marks: [
      { value: 100000, label: "0.1" },
      { value: 200000, label: "0.2" },
      { value: 300000, label: "0.3" },
      { value: 400000, label: "0.4" },
    ],
    minSal: 0,
  },
  {
    rowNumner: "r2",
    key: 2,
    value: "0.5M - 1.0M",
    partitation: ["0.6", "0.7", "0.8", "0.9"],
    marks: [
      { value: 600000, label: "0.6" },
      { value: 700000, label: "0.7" },
      { value: 800000, label: "0.8" },
      { value: 900000, label: "0.9" },
    ],
    minSal: 500000,
  },
  {
    rowNumner: "r3",
    key: 3,
    value: "1.0M - 1.5M",
    partitation: ["1.1", "1.2", "1.3", "1.4"],
    marks: [
      { value: 1100000, label: "1.1" },
      { value: 1200000, label: "1.2" },
      { value: 1300000, label: "1.3" },
      { value: 1400000, label: "1.4" },
    ],
    minSal: 1000000,
  },
  {
    rowNumner: "r4",
    key: 4,
    value: "1.5M - 2.0M",
    partitation: ["1.6", "1.7", "1.8", "1.9"],
    marks: [
      { value: 1600000, label: "1.6" },
      { value: 1700000, label: "1.7" },
      { value: 1800000, label: "1.8" },
      { value: 1900000, label: "1.9" },
    ],
    minSal: 1500000,
  },
  {
    rowNumner: "r5",
    key: 5,
    value: "2.0M - 2.5M",
    partitation: ["2.1", "2.2", "2.3", "2.4"],
    marks: [
      { value: 2100000, label: "2.1" },
      { value: 2200000, label: "2.2" },
      { value: 2300000, label: "2.3" },
      { value: 2400000, label: "2.4" },
    ],
    minSal: 2000000,
  },
  {
    rowNumner: "r6",
    key: 6,
    value: "2.5M - 3.0M",
    marks: [
      { value: 2600000, label: "2.6" },
      { value: 2700000, label: "2.7" },
      { value: 2800000, label: "2.8" },
      { value: 2900000, label: "2.9" },
    ],
    partitation: ["2.6", "2.7", "2.8", "2.9"],
    minSal: 2500000,
  },
];

export const EMPLOYEE_COUNT = [
  "0 - 100",
  "101 - 500",
  "501 - 1000",
  "1001 - 5000",
  "above5000",
];

export const TIME_PERIOD = [
  {
    key: "CW",
    value: "Current Week",
  },
  {
    key: "CM",
    value: "Current Month",
  },
  {
    key: "CY",
    value: "Current YEAR",
  },
  {
    key: "PW",
    value: "Previous Week",
  },
  {
    key: "PM",
    value: "Previous Month",
  },
  {
    key: "PY",
    value: "Previous Year",
  },
  {
    key: "PSM",
    value: "Previous Six Months",
  },
];

export const OFFSETS = [
  "-12:00",
  "-11:00",
  "-10:00",
  "-09:30",
  "-09:00",
  "-08:00",
  "-07:00",
  "-06:00",
  "-05:00",
  "-04:00",
  "-03:30",
  "-03:00",
  "-02:00",
  "-01:00",
  "±00:00",
  "+01:00",
  "+02:00",
  "+03:00",
  "+03:30",
  "+04:00",
  "+04:30",
  "+05:00",
  "+05:30",
  "+05:45",
  "+06:00",
  "+06:30",
  "+07:00",
  "+08:00",
  "+08:30",
  "+08:45",
  "+09:00",
  "+09:30",
  "+10:00",
  "+10:30",
  "+11:00",
  "+12:00",
  "+12:45",
  "+13:00",
  "+14:00",
];

export const COROUSEL_CONSTANT = [
  {
    content:
      "I joined the MatchupIT platform. Interestingly, it allows direct communication channels between companies on this platform with an individual. I input all my data on this platform by just spending 20 minutes. Next week, I plan to join a couple of communities to write blogs and interact with other experts. This platform will be popular in no time.",
    image: team1,
    name: "Yogesh Mishra",
    designation: "Digital and Cloud Advisor,",
    location: " Atlanta, Georgia - USA",
  },
  {
    content:
      "I joined the MatchupIT platform. Interestingly, it allows direct communication channels between companies on this platform with an individual. I input all my data on this platform by just spending 20 minutes. Next week, I plan to join a couple of communities to write blogs and interact with other experts. This platform will be popular in no time.",
    image: team2,
    name: "Shobhika Somani",
    designation: "Independent Strategy Consultant, Healthcare ,",
    location: "  Atlanta, Georgia - USA",
  },
  {
    content:
      "Creating a profile on MatchupIT is a must for every IT professional. It takes 15-20 minutes to create a digital profile on MatchupIT. The most important part is that the focus is not on how many people are connected socially but on growing by collaborating. The recruiters in the companies looking for talent should use this platform to recruit talent.",
    image: team3,
    name: "Amol Malhotra",
    designation: "Head Software Delivery,",
    location: " Atlanta, Georgia - USA",
  },
  {
    content:
      "I was apprehensive about creating my platform on MatchupIT, but once I made my profile, I realized the power of this platform. The ability to join a community of CIOs and collaborate with others with similar interests benefits. MatchupIT is an excellent platform for sourcing quality candidates for companies. It is becoming a go-to resource for collaboration and reaching quality technology experts.",
    image: team4,
    name: "Cindy Taylor",
    designation: "Former CIO, Fractional COO & CIO for startups,",
    location: " Atlanta, Georgia - USA",
  },
];
