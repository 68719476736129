// src/JitsiMeet.js

import { JaaSMeeting } from "@jitsi/react-sdk";
import React, { useEffect, useRef } from "react";
import { JITSI_APP_ID } from "../../../constant/constant";
import { useLocation } from "react-router-dom";
import "./MeetingContainer.scss";
const JitsiMeet = ({ handleEndCall, joinMeeting }) => {
  const jitsiApi = useRef(null);
  const location = useLocation();

  const handleApiReady = (api) => {
    jitsiApi.current = api;
  };

  const endMeeting = () => {
    if (jitsiApi.current) {
      jitsiApi.current.executeCommand("hangup");
    }
  };
  useEffect(() => {
    // End the meeting when leaving the meeting route
    return () => {
      endMeeting();
    };
  }, [location.pathname]);
  return (
    <div className="overrideMeetingContainer">
      <JaaSMeeting
        Height="500px"
        appId={JITSI_APP_ID}
        roomName={joinMeeting}
        userInfo={{
          displayName: localStorage.getItem("firstName"),
        }}
        configOverwrite={{
          disableThirdPartyRequests: true,
          disableLocalVideoFlip: true,
          backgroundAlpha: 0.5,
          HIDE_INVITE_MORE_HEADER: true,
        }}
        interfaceConfigOverwrite={{
          VIDEO_LAYOUT_FIT: "nocrop",
          MOBILE_APP_PROMO: false,
          TILE_VIEW_MAX_COLUMNS: 4,
        }}
        onApiReady={handleApiReady}
        onReadyToClose={handleEndCall}
        // spinner = { SpinnerView }
        // onApiReady = { (externalApi) => { ... } }
      />
    </div>
  );
};

export default JitsiMeet;
