import { CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsCard from "../../../library/custom/newsCard/NewsCard";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import TrendingCommunityCard from "../../../library/custom/trendingCommunityCard/TrendingCommunityCard";
import ProfileCard from "../../profile/profileCard";
import "./Dashboard.scss";
import NoUser from "../../../assets/noUser.png";
import Loader from "../../../library/common/Loader";
import SearchedUserCard from "../../../library/custom/searchCard/SearchedUserCard";
import { useNavigate } from "react-router";
import {
  EDIT_PROFILE,
  MESSAGES,
  SEARCH,
  VIEW_PROFILE,
} from "../../../constant/routeContant";
import { postUserSearch } from "../../../redux/actions/searchAction";
import CompleteProfileCard from "./CompleteProfileCard";
import { ACCOUNT_TYPE } from "../../../constant/constant";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [isdash, setIsdash] = useState(true);
  const getNews = () => {
    const options = {
      method: 'GET',
      url: 'https://news-api14.p.rapidapi.com/v2/search/articles',
      params: {
        query: 'technology',
        language: 'en',
        limit: '5',
        page: '1'
    
      },headers: {
        "x-rapidapi-key": "d1e18b2825mshde3b9b52c7cfcb5p141a7fjsn47313d450995",
        "x-rapidapi-host": "news-api14.p.rapidapi.com",
      },
    };
    axios
      .request(options)
      .then((response) => {
        console.log(response.data);
        setData(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStocks = () => {
    // var optionsNews = {
    //   method: "GET",
    //   url: "https://yfapi.net/v11/finance/quoteSummary/AAPL",
    //   params: { modules: "defaultKeyStatistics,assetProfile" },
    //   headers: {
    //     "x-api-key": "I4R2rlGa1c9KXwrCyVybR1UtyBpJLX7Q1jdgyVfR",
    //   },
    // };

    // axios
    //   .request(optionsNews)
    //   .then(function (response) {
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });

    var options = {
      method: "GET",
      url: "https://yfapi.net/v6/finance/quote",
      params: {
        symbols: "^DJI,^IXIC,^GSPC,^NSEI,^BSESN",
      },

      headers: {
        "X-Api-Key": "I4R2rlGa1c9KXwrCyVybR1UtyBpJLX7Q1jdgyVfR",
      },
    };

    axios
      .request(options)
      .then((response) => {
        setStocks(response.data.quoteResponse.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getNews();
    getStocks();
  }, []);

  const userData = useSelector((state) => state?.userDetails?.data?.response);

  const myCommunities = useSelector((state) => state?.myCommunity?.data);
  const recentSearches = useSelector(
    (state) => state?.recentSearches?.data?.response?.recentsearch
  );
  const topCommunity = useSelector((state) => state?.topCommunity?.data?.data);
  const showLoader = useSelector((state) => state?.myCommunity?.isLoading);
  function onProfileClick(id) {
    navigate(VIEW_PROFILE, {
      state: { id: id },
    });
  }
  const [searchText, setsearchText] = useState("");
  const [users, setusers] = useState(null);
  const searchMapData = useSelector(
    (state) => state?.userSearchReducer?.data?.response?.userList
  );
  useEffect(() => {
    if (searchText === "") setusers(null);
    else setusers(searchMapData);
  }, [searchMapData]);

  function mapSearch(input) {
    const data = {
      name: true,
      searchText: input,
      zipcode: "",
      pageNo: "1",
      country: "",
      city: "",
    };
    setsearchText(input);
    dispatch(postUserSearch(data));
  }
  function onMsgClick(id) {
    navigate(MESSAGES, {
      state: { id: id },
    });
  }
  function onLoactionClick(lat, lng) {
    navigate(SEARCH, {
      state: { lat: lat, lng: lng },
    });
  }
  const MYCOMMUNITYURL = "/view/community";
  function handleMYClick(data, isdash) {
    navigate(MYCOMMUNITYURL, {
      state: {
        data: data,
        isdash: isdash,
      },
    });
  }
  const dialCode = userData?.basicDetails?.dial_code;
  const mobileNumber =
    userData?.basicDetails?.phone ?? userData?.basicDetails?.telephone ?? "";
  const phoneNumber = dialCode + " " + mobileNumber;

  function onCompleteCardClick() {
    navigate(EDIT_PROFILE);
  }
  return (
    <div className="overrideDashboard">
      {showLoader ? (
        <Loader color="inherit" />
      ) : (
        <Grid container>
          <Grid container item lg={8}>
            <Grid mb={1} container>
              {userData?.profileCompletionPercentage < 80 && (
                <CompleteProfileCard onClick={onCompleteCardClick} />
              )}
            </Grid>
            <Grid
              container
              item
              lg={10.3}
              xs={12}
              className="SearchPeopleStyle"
            >
              <SearchBar
                searchbarInput={searchText}
                placeholder={"Search for people"}
                handleSearch={mapSearch}
              />
              {users && (
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  className="searchuserConatiner"
                >
                  {searchMapData?.map((i, j) => (
                    <Grid
                      container
                      item
                      lg={12}
                      xs={12}
                      sm={12}
                      md={12}
                      justifyContent={"left"}
                      flexWrap={"wrap"}
                      className="cardBody"
                      mt={j == 0 && 1}
                      p={1}
                      onClick={() =>
                        navigate(VIEW_PROFILE, {
                          state: { id: i?.basicDetails?.id },
                        })
                      }
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        sm={12}
                        md={12}
                        item
                        container
                        justifyContent={"left"}
                        alignItems={"center"}
                        className="card"
                      >
                        <Grid item container lg={12} xs={12} sm={12} md={12}>
                          <div className="searchCardContainer">
                            <div className="serachImageStyle">
                              <img
                                src={
                                  i?.basicDetails?.profile_pic === null
                                    ? NoUser
                                    : i?.basicDetails?.profile_pic
                                }
                                alt=""
                                height={"50px"}
                                width={"48px"}
                              />
                            </div>
                            <div className="nameAdressContainer">
                              <div className="name">
                                {i?.basicDetails?.first_name +
                                  " " +
                                  i?.basicDetails?.last_name}
                              </div>
                              <div className="dot"></div>
                              <div className="adress">
                                {i?.profile?.work_experience
                                  ?.designations[0] === (null || undefined)
                                  ? ""
                                  : i?.profile?.work_experience
                                      ?.designations[0]}

                                {i?.profile?.work_experience?.orgNames[0] ===
                                (null || undefined)
                                  ? ""
                                  : ` at ${i?.profile?.work_experience?.orgNames[0]}`}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid
              mt={5}
              container
              lg={12}
              className="recentSearchesContainerStyle"
            >
              <Grid className="" item lg={5.5} xs={12} textAlign={"left"}>
                <h1 className="recenetSearchHeading">Recent Searches</h1>
                <div className="recentSearchContainer">
                  {recentSearches?.length > 0 ? (
                    recentSearches?.map((item, i) => (
                      <Grid mt={1} key={item?.individualId}>
                        <SearchedUserCard
                          name={
                            item?.details?.basicDetails?.first_name +
                            " " +
                            item?.details?.basicDetails?.last_name
                          }
                          designation={
                            item?.details?.profile?.work_experience
                              ?.designations[0]
                          }
                          city={item?.details?.basicDetails?.city}
                          state={item?.details?.basicDetails?.state}
                          country={item?.details?.basicDetails?.country_name}
                          company={
                            item?.details?.profile?.work_experience?.orgNames[0]
                          }
                          profilePic={
                            item?.details?.basicDetails?.profile_pic === null
                              ? NoUser
                              : item?.details?.basicDetails?.profile_pic
                          }
                          btnLabel={"Message"}
                          v2={true}
                          onProfileClick={() =>
                            onProfileClick(item?.individualId)
                          }
                          onMsgClick={() => onMsgClick(item)}
                          onLoactionClick={() =>
                            onLoactionClick(
                              item?.details?.basicDetails?.latitude,
                              item?.details?.basicDetails?.longitude
                            )
                          }
                        />
                      </Grid>
                    ))
                  ) : (
                    <h5>No Recent Searches</h5>
                  )}
                </div>
              </Grid>
              <Grid item lg={5.5} xs={12} textAlign={"left"}>
                <h1 className="recenetSearchHeading">Top Communities</h1>
                <div className="recentSearchContainer">
                  {topCommunity?.length > 0 ? (
                    topCommunity?.map((item, i) => (
                      <Grid mt={1} key={i}>
                        <TrendingCommunityCard
                          label1={item.title}
                          label2={item.userCount}
                          icon={item?.icon}
                          handleClick={() => handleMYClick(item, isdash)}
                        />
                      </Grid>
                    ))
                  ) : (
                    <h5>No Top Community</h5>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              mt={2}
              xs={12}
              sm={12}
              md={12}
              textAlign={"left"}
            >
              <h1>News</h1>
              <Grid
                item
                lg={11.6}
                xs={12}
                sm={12}
                md={12}
                className={data?.length > 0 ? "newsGrid" : "NonewsGrid"}
                mt={2}
                mb={2}
              >
                {data?.length > 0 ? (
                  data?.map((item, index) => (
                    <a className="link" href={item?.url} target="_blank">
                      <NewsCard
                        className="cardStyle"
                        label2={item?.authors[0]}
                        image={item?.thumbnail ? item?.thumbnail : ""}
                        label1={item.title}
                        alt={"image"}
                      />
                    </a>
                  ))
                ) : (
                  <h5 className="NoDataToshowStyle">No News to show</h5>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              mb={4}
              textAlign={"left"}
            >
              <h1>Markets</h1>
              <Grid
                item
                lg={11.6}
                xs={12}
                sm={12}
                md={12}
                mt={2}
                className="marketGrid"
              >
                {data?.length > 0 ? (
                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col-xs-12">
                      <div className="profile-gen">
                        {stocks.length > 0 ? (
                          stocks.map((item, i) => (
                            <button
                              style={{
                                margin: "5px",
                                border: "1px solid grey",
                                borderRadius: "15px",
                                padding: "5px",
                                minWidth: "110px",
                                background: "white",
                              }}
                              key={i}
                            >
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "12px",
                                  fontWeight: "bolder",
                                }}
                              >
                                {item.shortName ===
                                "Dow Jones Industrial Average"
                                  ? "Dow Jones"
                                  : item.shortName}
                              </p>
                              <p style={{ margin: "0", fontSize: "10px" }}>
                                {Number(item.regularMarketPrice).toFixed(2)}
                              </p>
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "10px",
                                  color:
                                    item.regularMarketChange < 0
                                      ? "#FF0000"
                                      : "#27C400",
                                }}
                              >
                                <span
                                  style={{
                                    display:
                                      item.regularMarketChange < 0
                                        ? "none"
                                        : "inline",
                                  }}
                                >
                                  +
                                </span>
                                {Number(item.regularMarketChange).toFixed(2)} (
                                <span
                                  style={{
                                    display:
                                      item.regularMarketChange < 0
                                        ? "none"
                                        : "inline",
                                  }}
                                >
                                  +
                                </span>
                                {Number(
                                  item.regularMarketChangePercent
                                ).toFixed(2)}
                                %)
                              </p>
                            </button>
                          ))
                        ) : (
                          <h5 className="NoDataToshowStyle">
                            No Stocks to show
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <h5 className="NoDataToshowStyle">No Stocks to show</h5>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid className="profileGrid" p={3} container item lg={4}>
            <Grid item lg={12} xs={12}>
              <Grid item lg={12} xs={12}>
                <ProfileCard
                  label1={
                    userData?.basicDetails?.first_name === null ||
                    userData?.basicDetails?.first_name === undefined ||
                    userData?.basicDetails?.first_name === ""
                      ? "MatchupIT User"
                      : userData?.basicDetails?.first_name +
                        " " +
                        userData?.basicDetails?.last_name
                  }
                  image={
                    userData?.basicDetails?.account_type === "corporate"
                      ? userData?.basicDetails?.logo
                      : userData?.basicDetails?.profile_pic
                  }
                  label2={userData?.basicDetails?.email}
                  label3={phoneNumber}
                  socialAccounts={userData?.profile?.social_links}
                  dialCode={userData?.basicDetails?.dial_code}
                  corporate={ACCOUNT_TYPE() === "corporate" ? true : false}
                  name={
                    userData?.basicDetails?.name === undefined
                      ? "MatchupIT User"
                      : userData?.basicDetails?.name
                  }
                />
              </Grid>
              {ACCOUNT_TYPE() !== "corporate" && (
                <Grid textAlign={"left"} mt={3}>
                  <h1>My Communities</h1>
                  {myCommunities?.data?.length > 0 ? (
                    myCommunities?.data?.map((communities, i) => {
                      return (
                        <Grid mt={1} key={i}>
                          <TrendingCommunityCard
                            label1={communities.title}
                            label2={communities.userCount}
                            id={communities._id}
                            handleClick={() =>
                              handleMYClick(communities, isdash)
                            }
                            icon={communities?.icon}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <h5>No Communities Joined</h5>
                  )}
                </Grid>
              )}
              <Grid textAlign={"left"} mt={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
